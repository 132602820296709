@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.gpo-chatbot-container {
  background-color: $bg-color;
  height: 100vh;
  overflow: auto;
  &__banner {
    @include flex(column, space-between, center);
    padding: 2rem 0 0;
    width: 58vw;
    margin: 0 auto;
    img {
      @include square(4.5rem);
      margin-bottom: .5rem;
    }
    h1 {
      text-align: center;
      color: $grey-600;
      font: {
        size: 2rem;
        weight: 600;
      }
    }
    p {
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      color: $grey-600;
      width: 40vw;
      margin: .5rem 0;
    }
    ul {
      li {
        text-align: center;
        list-style: none;
        line-height: 1.5rem;
        position: relative;
        font: {
          size: 0.85rem;
          weight: 600;
        }
        &:before {
          content: "";
          @include square(0);
          border-top: .25rem solid transparent;
          border-bottom: .25rem solid transparent;
          border-left: .4rem solid $grey-600;
          position: absolute;
          margin-left: -0.9rem;
          top: 0.5rem;
        }
      }
    }
  }
}
