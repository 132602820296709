@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.logout-container{
    background-color: $bg-color;
    &__content{
        background-color: $bg-color;
        position: fixed;
        left: 0;
        width: 100vw;
        height: 90vh;
        animation: floatPage .75s ease-out;
        @include flex(row, center, center); 
        .content-wrapper{
            @include flex(column, flex-start, flex-start); 
            gap :1.5rem;
            width: 25rem;
            height: auto;
            padding: 2rem;
            box-shadow: 2px 1px 2px 1px rgb(0 0 0 / 5%);
            background-color: $white;
            border-radius: 0.4rem;
            &__logo{
                @include flex(row, flex-start, center);
                width: 100%; 
                .logo-image{
                    position: relative;
                    top: -0.5rem;
                }
                .logo-name{
                    span{
                        font-weight: 600;
                    }
                } 
            }
            &__message{
                @include flex(column, flex-start, flex-start); 
                width: 100%;
                .message-title{
                    span{
                        font-weight: 600;
                    }
                }
                .message-detail{
                    margin-top : 1rem;
                    span{
                        font-weight: 400;
                        font-size: 0.8rem;
                    }
                } 
            }
            &__action{
                @include flex(column, flex-end, flex-end); 
                width: 100%;
                button{
                    background-color: $yellow-400;
                    border-color:transparent;
                    font-weight: 600;
                    padding: 0.5rem 2.2rem;
                    border-radius: 0.4rem;
                }
            }
        }
       
        
    }
}
@keyframes floatPage {
    0% {
        top:-100%;
    }
    100% {
        top: 0;
    }
}