@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";
.loader-wrapper {
  position: relative;
  @include square(100%);
  z-index: 999;
  &__container {
    z-index: 99;
    position: absolute;
    background-color: $bg-color;
    @include square(100%);
    z-index: 99;
    span {
      color: $grey-600;
      position: absolute;
      display: flex;
      top: 44%;
      left: 50%;
      transform: translate(-44%, -50%);
      z-index: 9;
      font: {
        size:1.2rem;
      }
    }
    ul {
      display: flex;
      position: relative;
      width: 150px;
      height: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      li {
        @include object(10px, 10px, null);
        list-style: none;
        border-radius: 50%;
        position: absolute;
        left: 0;
        transform: translatex(-50%);
        animation: load 1s cubic-bezier(0.58, -0.15, 0.44, 1.15) infinite
          alternate;
        @for $i from 1 through 5 {
          &:nth-child(#{$i}) {
            background: nth($colors, $i);
            animation-delay: 0.1s * $i;
          }
        }
        @keyframes load {
          100% {
            left: 100%;
          }
        }
      }
    }
  }
  &.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }
}
