$shadow-color: #c4c4cd59;
$bg-color:#f0f0f0;
$app-bg : #000;
$black :#1A1A24;
$white : #fff;
$yellow-400 :#FFE600;
$grey-600 :#23232F;
$grey-550 :#2E2E3C;
$grey-500 :#2E2E38;
$grey-450 : #3A3A4A;
$grey-300 : #C4C4CD;
$grey-200 : #EAEAF2;

$green-400: #2DB757;
$green-500 : #189D3E;
$green-600 : #168736;
$orange-300 : #FF810A;
$orange-200: #FF9831;
$red-500 : #E0362C;
$red-400: #FF4236;
$blue-400 : #188CE5;
$blue-450 : #2f53f0;

$bot-bg : #272732;
$bot-color : #BABABA;
$bubble-grey :#D9D9D9;
$serach-bg:#f6f6fa;
$disabled : #d4d4d4;

$colors :#42A5F5 #2196F3 #1E88E5 #1976D2 #1565C0;

// Images
$image-base: "../../assets/images/";
$background-image-new : $image-base+"bg_new.png";
$background-image : $image-base+"bg.png";


$like : '../../assets/images/like.png';
$dislike : '../../assets/images/dont-like.png';
