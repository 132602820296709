@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.gpo-pdf-viewer {
  // width: 650px;
  position: relative;
  align-self: flex-start;

  .loader-wrapper {
    position: absolute;
    width: 99%;
    height: calc(100vh - 10rem);
  }

  .doc {
    margin-top: 1rem;
    border: 0.05rem solid #C4C4CD;
    height: calc(100vh - 8rem);

    .pdf-page {
      break-before: page;
      margin-bottom: 1rem;
    }
  }

  .gpo-pdf-header {
    @include flex(row, space-between, center);
    font-size: 14px;
    position: relative;
    background-color: $grey-600;
    color: $white;
    padding: 0 1rem;
    cursor: default;

    .close_btn {
      @include flex();
      position: absolute;
      background-color: $white;
      height: 100%;
      width: 2rem;
      left: -2rem;
      top: 0;
      cursor: pointer;
    }

    &__container-left {
      @include flex(row, flex-start, center);
      gap: 0.5rem;
      margin-right: 1.5rem;

      .doc-details__container {
        font-weight: 700;
        @include flex(row, flex-start, center);
        gap: 0.2rem;

        .doc-name {
          max-width: 5rem;
          @include ellipsis;
        }

        .page-numbers {
          font-weight: 300;
        }
      }

      .btn-wrapper {
        @include flex();
        gap: 0.2rem;

        img {
          cursor: pointer;
        }
      }
    }

    &__container-right {
      @include flex(row, flex-start, center);
      gap: 1rem;
      user-select: none;

      .navigate-btns {
        @include flex();
        padding: 0.5rem;
        font-weight: 300;
        gap: 0.5rem;
        background-color: $blue-400;

        .font-bold {
          font-weight: 700;
        }

        &__label {
          @include flex();
          gap: 0.2rem;
        }

        img {
          margin: 0 -0.5rem;
        }

        .next-btn,
        .prev-btn {
          cursor: pointer;
          background: none;
          border: none;
          padding: 0;
          margin: 0;
          @include flex();

          &__disabled {
            cursor: not-allowed;
            filter: invert(0.3);
          }
        }

        .prev-btn {
          transform: rotate(180deg);
        }
      }

      .maximize {
        @include flex();
        cursor: pointer;

        &__disabled {
          cursor: not-allowed;

          img {
            pointer-events: none;
          }
        }
      }
    }

  }

  .react-pdf__Document {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 9rem);
    @include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);
  }

  canvas {
    width: 100% !important;
  }
}

.fullscreen-modal {
  z-index: 10;
  position: fixed;
  left: 0;
  top: 0;
  @include square(100%);
  background-color: $white;

  header {
    @include flex(row, space-between, center);
    background-color: $grey-600;
    padding: 1rem;
    color: $white;

    img {
      cursor: pointer;
    }
  }

  &__body {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 3rem);
    @include scrollbars(0.6rem, darken($grey-600, 5%), $grey-450);

    .rpv-core__inner-pages {
      @include scrollbars(0.6rem, darken($grey-600, 5%), $grey-450);
    }
  }
}