@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";
@import "/src/assets/scss/typography";
$animationLength: 1.5s;
$animationRadius: 12px;
$dotSize: 8px;
.unauthorised {
  background: $bg-color;
  height: 100vh;
  @include flex(row, center, flex-start);
  &__container {
    @include flex(column, center, center);
    @include square(100%);
    .gpo-chat-header {
      background-color: $white;
      box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.05);
      position: relative;
      width: 100%;
      &__container {
        @include flex(row, space-between, center);
        padding: 1.5rem;
        width: 75vw;
        margin: 0 auto;
        .gpo-logo {
          span {
            font: {
              weight: 600;
            }
          }
        }
      }
    }
    .unauthorized-body {
      position: relative;
      @include flex(column, center, center);
      @include square(100%);
      perspective: 1000px;
      transition: all 1s ease;
      &.active {
        .access-confirm {
          transform: rotateY(180deg);
          .items {
            @include flex(column, center, center);
            transform: rotateY(180deg);
          }
        }
      }
      .grey-bg {
        position: absolute;
        height: 75%;
        right: 0;
        top: 0;
      }
      .unauthorized-texts {
        @include flex(column, center, center);
        h3 {
          margin: 1.5rem 0 1rem;
        }
        span {
          background: $grey-500;
          padding: 0.5rem 1rem;
          color: $white;
          cursor: pointer;
          font: {
            size: 0.8rem;
            family: $ey-font-regular;
          }
        }
        .unauthorised-bg {
          @include square(8rem);
        }
      }

      .access-confirm {
        background-color: $white;
        @include flex(column, center, center);
        padding: 2rem;
        min-width: 25rem;
        transition: transform 1s;
        transform-style: preserve-3d;
        transform: rotateY(0deg);
        animation: access 0.3s ease;

        .tick {
          background-color: #8ce8ad;
          @include square(2.5rem);
          border-radius: 50%;
          @include flex(row, center, center);
          img {
            @include square(1.5rem);
          }
        }
        h3 {
          margin: 1rem 0 0.25rem 0;
        }
        p {
          font-size: 0.8rem;
          line-height: 1.2rem;
        }
      }
    }
  }
}
.access-loader-wrapper {
  &__container {
    @include centre;
    .access-loader {
      position: relative;
      width: $animationRadius * 3 + $dotSize;
      height: $dotSize;
      margin: $animationRadius auto;
      h4 {
        color: $grey-550;
        left: -1rem;
        position: relative;
        top: 1.2rem;
        font-weight: 300;
      }
      .dot {
        display: inline-block;
        width: $dotSize;
        height: $dotSize;
        border-radius: $dotSize * 0.5;
        background: $grey-500;
        position: absolute;
        &.dot_1 {
          animation: animateDot1 $animationLength linear infinite;
          left: $animationRadius;
          background: $yellow-400;
        }
        &.dot_2 {
          animation: animateDot2 $animationLength linear infinite;
          animation-delay: $animationLength / 3;
          left: $animationRadius * 2;
        }

        &.dot_3 {
          animation: animateDot3 $animationLength linear infinite;
          left: $animationRadius;
        }

        &.dot_4 {
          animation: animateDot4 $animationLength linear infinite;
          animation-delay: $animationLength / 3;
          left: $animationRadius * 2;
        }
      }
    }
    @keyframes animateDot1 {
      0% {
        transform: rotate(0deg) translateX(-$animationRadius);
      }
      25% {
        transform: rotate(180deg) translateX(-$animationRadius);
      }
      75% {
        transform: rotate(180deg) translateX(-$animationRadius);
      }
      100% {
        transform: rotate(360deg) translateX(-$animationRadius);
      }
    }

    @keyframes animateDot2 {
      0% {
        transform: rotate(-0deg) translateX(-$animationRadius);
      }
      25% {
        transform: rotate(-180deg) translateX(-$animationRadius);
      }
      75% {
        transform: rotate(-180deg) translateX(-$animationRadius);
      }
      100% {
        transform: rotate(-360deg) translateX(-$animationRadius);
      }
    }

    @keyframes animateDot3 {
      0% {
        transform: rotate(0deg) translateX($animationRadius);
      }
      25% {
        transform: rotate(180deg) translateX($animationRadius);
      }
      75% {
        transform: rotate(180deg) translateX($animationRadius);
      }
      100% {
        transform: rotate(360deg) translateX($animationRadius);
      }
    }

    @keyframes animateDot4 {
      0% {
        transform: rotate(-0deg) translateX($animationRadius);
      }
      25% {
        transform: rotate(-180deg) translateX($animationRadius);
      }
      75% {
        transform: rotate(-180deg) translateX($animationRadius);
      }
      100% {
        transform: rotate(-360deg) translateX($animationRadius);
      }
    }
  }
}
@keyframes access {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
