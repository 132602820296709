@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.gpo-tooltip {
  position: absolute;
  left: 2rem;
  width: 18rem;
  cursor: default;
  z-index: 999;

  &__container {
    background-color: $grey-600;
    padding: 0.75rem;
    border-radius: .5rem;

    .row {
      @include grid(6rem 1fr, center, 0);

      span {
        &.title {
          color: $bot-color;
          flex: 0.5;

          font: {
            size: 0.7rem;
          }
        }

        &.status {
          border-radius: 2rem;
          border: 0.09rem solid $grey-200;
          padding: 0 0.5rem;
          display: flex;
          width: fit-content;

          font: {
            size: 0.8rem;
          }

          &--excellent {
            border-color: $green-400;
          }

          &--poor {
            border-color: $red-400;
          }

          &--fair {
            border-color: $orange-200;
          }
        }

        &.view {
          @include flex(row, flex-start, center);
          flex: 1;
          gap: 0.2rem;
          cursor: pointer;

          font: {
            size: 0.85rem;
            weight: 700;
          }

          img {
            width: 0.7rem !important;
            margin-left: 0.3rem;
          }
        }

        &.pages {
          flex: 1;

          font: {
            size: 0.85rem;
          }
        }
      }

      &--source-data {
        align-items: baseline;
      }

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      &:last-child {
        display: block;
      }
    }
  }

  &::before {
    content: "";
    display: inline-block;
    width: 1rem;
    height: 0;
    border-top: solid 1rem transparent;
    border-bottom: solid 1rem transparent;
    border-right: solid 1rem $grey-600;
    left: -2rem;
    top: 3.5rem;
    position: relative;
  }
}