@import 'mixins';
@import 'variables';
@import 'typography';
html,
body {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--body-bg);
    color: var(--body-color);
    line-height: inherit;
    height: 100vh;
    overflow: hidden;
    font: {
        family: $ey-font-light;
    }
}

* {
    box-sizing: border-box;
    outline: none;
}

:root {
    --table-row-hover-bg-color: #{transparent};
}

html,
body,
div,
span,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
img,
strong,
sub,
sup,
b,
u,
i,
center,
ol,
ul,
li,
form,
label,
article,
figure,
figcaption,
footer,
header,
nav,
section {
    margin: 0;
    padding: 0;
    border: 0;
}

button{
    cursor: pointer;
}
