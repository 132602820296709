@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.connecting {
  margin-top: 2rem;
  .container {
    @include flex(column, center, center);
    h4 {
      margin-bottom: 0.5rem;
      display: flex;
      .status {
        margin-left: 0.3rem;
        @include flex(row, flex-start, center);
        gap: 0.2rem;
        position: relative;
        bottom: -.2rem;
        span {
          @include square(0.175rem);
          border-radius: 100%;
          animation: calling 1s backwards infinite;
          position: relative;
          top: 0.1rem;
          @for $i from 1 through 3 {
            &:nth-child(#{$i}) {
              animation-delay: $i * 0.3 + s;
            }
          }
        }
      }
    }
    .items {
      @include flex(row, center, center);
      min-width: 40rem;
      .component {
        position: relative;
        &.PC {
          height: 3rem;
          width: 15%;
          border-radius: 0.2rem;
          position: relative;
          .laptop-screen {
            position: relative;
            left: 7%;
            height: 94%;
            width: 86%;
            background-color: #333;
            padding: 3.5%;
            .laptop-content {
              @include square(100%);
              background-color: #37474f;
              overflow: hidden;
              position: relative;
              .code {
                position: absolute;
                width: 92%;
                height: 400%;
                padding: 4%;
                animation: slide 1s linear infinite;
                top: 0%;
                margin-left: 0.5rem;
                .line {
                  width: 40%;
                  height: 1%;
                  margin-bottom: 4%;
                  border-radius: 50px;
                  background-color: $orange-300;
                }

                .line02,
                .line03,
                .line04,
                .line06,
                .line07,
                .line08,
                .line10,
                .line11,
                .line12,
                .line14,
                .line15 {
                  margin-left: 0.5rem;
                  background-color: $green-500;
                }
              }
            }
          }
          .laptop-base {
            background-color: #bbb;
            height: 6%;
            border-radius: 0 0 1vw 1vw;
            .laptop-trackpad {
              position: relative;
              left: 50%;
              background-color: #666;
              width: 20%;
              height: 30%;
              border-radius: 0 0 1vw 1vw;
              transform: translateX(-50%);
            }
          }
        }
        &.signals {
          width: 30%;
          height: 5rem;
          .dot {
            @include square(0.45rem);
            background: $grey-550;
            display: inline-block;
            border-radius: 50%;
            position: absolute;
            left: 30%;
            top: 50%;
            opacity: 0;
            transform: translate(-50%, -50%);
            animation: blink 0.5s ease-in infinite alternate;
            &:first-child {
              margin-left: 0px;
            }
            &:nth-child(2) {
              margin-left: 2rem;
              animation-delay: 0.1s;
            }
            &:last-child {
              margin-left: 4rem;
              animation-delay: 0.2s;
            }
          }
        }
        &.server {
          width: 9%;
          height: 4.5rem;
          border: 0.15rem solid $grey-600;
          border-radius: 5px;
          position: relative;
          background-color: #37474f;
          @include flex(column, space-between, flex-start);
          padding: 0.5rem 0 0.5rem 0.65rem;
          .line {
            display: block;
            width: 85%;
            height: 0.25rem;
            background: rgba(255, 255, 255, 0.1);
            box-shadow: 0 0 0 0.05rem black inset;
            &:before {
              content: "";
              position: relative;
              left: -0.45rem;
              display: block;
              @include square(0.25rem);
              background: $green-500;
              border: 1px solid black;
              border-radius: 6px;
            }
            &:nth-child(1):before {
              background: rgba(255, 255, 255, 0.15);
              animation: pulse_two 0.1s infinite alternate;
            }
            &:nth-child(3):before {
              background: rgba(255, 255, 255, 0.15);
              animation: pulse 0.5s infinite alternate;
            }
            &:nth-child(5):before {
              background: rgba(255, 255, 255, 0.15);
              animation: pulse_three 0.7s infinite alternate;
            }
            &:nth-child(1):before {
              background: rgba(255, 255, 255, 0.15);
              animation: pulse_two 0.1s infinite alternate;
            }
            &:nth-child(2):before {
              background: rgba(255, 255, 255, 0.15);
              animation: pulse_two 0.175s infinite alternate;
            }
            &:nth-child(4):before {
              background: rgba(255, 255, 255, 0.15);
              animation: pulse_two 0.1s infinite alternate;
            }
          }
        }
      }
    }
  }
  @keyframes blink {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
@keyframes slide {
  0% {
    top: 0%;
  }
  100% {
    top: -40%;
  }
}
@keyframes pulse {
  0% {
    background: rgba(255, 255, 255, 0.15);
  }
  100% {
    background: $red-500;
  }
}

@keyframes pulse_three {
  0% {
    background: rgba(255, 255, 255, 0.15);
  }
  100% {
    background: $orange-300;
  }
}

@keyframes pulse_two {
  0% {
    background: rgba(255, 255, 255, 0.15);
  }
  100% {
    background: $green-500;
  }
}
@keyframes calling {
  from {
    background: $grey-600;
    opacity: 0.1;
  }
  to {
    background: $grey-600;
    opacity: 0.9;
  }
}